import { environment } from '../environments/environment';

export const APP_CONFIG = {
  active_profile: 'prod', // test, prod,dev
  prod: {
    url_gateway: 'https://api.production.rapidask.net',
    url_hosted_ui:'https://rapidask-prod.auth.us-east-1.amazoncognito.com/login?client_id=2j8eo90pbf3rd0vav5jqh7tugh&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fapp.rapidask.net%2Fcallback',
    url_logout_hosted_ui:'https://rapidask-prod.auth.us-east-1.amazoncognito.com/logout?client_id=2j8eo90pbf3rd0vav5jqh7tugh&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fapp.rapidask.net%2Fcallback',
  },
  test: {
    url_gateway: 'https://apis.inventario.release.dosm14.com',
    url_hosted_ui:'https://inventario-release.auth.us-east-1.amazoncognito.com/login?client_id=5tm5gpgum6v2vo0rj66cc2j5ku&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fapp.inventario.dosm14.com%2Fcallback',
    url_logout_hosted_ui:'https://inventario-release.auth.us-east-1.amazoncognito.com/logout?client_id=5tm5gpgum6v2vo0rj66cc2j5ku&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=https%3A%2F%2Fapp.inventario.dosm14.com%2Fcallback',
 },
  dev: {
    url_gateway: 'http://localhost:8001',
    url_hosted_ui:'https://rapidask-prod.auth.us-east-1.amazoncognito.com/login?client_id=2j8eo90pbf3rd0vav5jqh7tugh&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fcallback',
    url_logout_hosted_ui:'https://rapidask-prod.auth.us-east-1.amazoncognito.com/logout?client_id=2j8eo90pbf3rd0vav5jqh7tugh&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A4200%2Fcallback',
 }

};